<template>
  <a-modal
      :title="title"
      :width="570"
      :visible="visible"
      :confirmLoading="isLoading"
      :maskClosable="false"
      :destroyOnClose="true"
      :centered="true"
      :footer="null"
      @cancel="cancel"
  >
    <div>
      <span style="font-size: 18px;font-weight: bold">选择公里数</span>
      <div class="" style="display: flex;margin-top: 10px">
        <div @click="selectkm('start_km')"
             style="padding: 20px;border: 3px solid #777;border-radius: 10px;cursor: pointer">
          <a style="font-weight: bold;font-size: 18px" v-if="start_km!=''">{{ start_km }}km</a>
          <span v-else>选择起始公里数</span>
        </div>
        <div @click="selectkm('end_km')"
             style="padding: 20px;border: 3px solid #777;border-radius: 10px;margin-left: 20px;cursor: pointer">
          <a style="font-weight: bold;font-size: 18px" v-if="end_km!=''">{{ end_km }}km</a>
          <span v-else>选择结尾公里数</span>
        </div>
      </div>
    </div>

    <div style="margin-top: 20px;font-size: 18px;font-weight: bold">
      当前所跑公里数：<a>{{ kilometers }}km</a>
    </div>

    <div style="margin-top: 20px;font-size: 18px;font-weight: bold">
      <template v-if="personnel_type==0">
        驾驶员每公里提成金额：<span style="color: red">￥</span>{{ price }}元 / km
      </template>
      <template v-if="personnel_type==1">
        押运员每公里提成金额：<span style="color: red">￥</span>{{ price }}元 / km
      </template>
    </div>
    <div style="margin-top: 20px;font-size: 18px;font-weight: bold">
      当前提成金额：<span style="color: red">￥</span>{{ net_salary }}
    </div>

    <div style="margin-top: 20px;text-align: center">
      <a-button type="primary" @click="jiesuan">确认</a-button>
    </div>
    <refuelog
        ref="refuelog"
        @suss="setkm"
    />
  </a-modal>
</template>
<script>
import refuelog from '../refuelLog/Index.vue'
import * as Api from "@/api/setting/store";
import * as payrollApi from '@/api/personnel/payroll'

export default {
  components: {
    refuelog
  },
  name: "SettlementKilometers",
  data() {
    return {
      title: ' ',
      visible: false,
      isLoading: false,
      price: 0,
      kilometers: 0,

      start_km: '',
      end_km: '',
      key: '',
      net_salary: 0,

      personnel_type:0
    }
  },
  methods: {
    getDetail() {
      this.isLoading = true
      Api.detail('salary')
          .then(result => {
            this.price = this.personnel_type==0?result.data.values.driver_kilometer_price:result.data.values.supercargo_kilometer_price
          })
          .finally(result => {
            this.isLoading = false
          })
    },

    selectkm(i) {
      this.key = i
      this.$refs.refuelog.open(this.item)
    },
    setkm(e) {
      this[this.key] = e.kilometers
      if (this.start_km !== '' && this.end_km !== '') {
        this.kilometers = this.end_km - this.start_km

        this.net_salary = this.kilometers * this.price
      }
      console.log(e)
    },
    open(item) {
      console.log(item)
      if(item.personnel){
        this.personnel_type = item.personnel.personnel_type
      }
      this.item = item
      this.visible = true
      this.getDetail()
    },
    cancel() {
      this.visible = false
      this.start_km = ''
      this.end_km = ''
      this.kilometers = 0
    },
    async jiesuan() {
      const {} = await payrollApi.edit({
        payroll_id: this.item.payroll_id,
        form: {kilometer: this.kilometers, commission: this.net_salary}
      })
      this.$emit('handleSubmit')
      this.cancel()
    }
  }
}
</script>
<style scoped lang="less">

</style>
