<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="发放方式" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['grant_type', { initialValue: 0,rules: [{ required: true }] }]"
              button-style="solid"
          >
            <a-radio-button :value="0">
              微信
            </a-radio-button>
            <a-radio-button :value="1">
              现金
            </a-radio-button>
            <a-radio-button :value="2">
              银行转账
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="发放金额" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number
              style="width: 100%"
              v-decorator="['amount', { rules: [{ required: true ,message:'请输入本次发放金额'}] }]"
              placeholder="请输入本次发放金额"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import * as Api from '@/api/personnel/grantlog'
import {UploadFile} from '@/components'
import _ from "lodash";

export default {
  props: ['payroll_id'],
  components: {
    UploadFile
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: ""
    }
  },
  methods: {

    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '发放'
      this.visible = true
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑费用'
      this.visible = true

      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['additional_type', 'additional_name', 'amount'])
        setFieldsValue(data)
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        values.payroll_id = this.payroll_id
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({paId: this.record['pa_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }
  }
}
</script>
