import {axios} from '@/utils/request'

const pub = "personnelPayroll";
/**
 * api接口列表
 */
const api = {
    info: '/' + pub + '/info',
    getDetails: '/' + pub + '/getDetails',
    list: '/' + pub + '/list',
    add: '/' + pub + '/add',
    edit: '/' + pub + '/edit',
    entry: '/' + pub + '/entry',
    delete: '/' + pub + '/delete',
    changeStatus: '/' + pub + '/changeStatus',
    saveStatus: '/' + pub + '/saveStatus',
    depart: '/' + pub + '/depart',
    all: '/' + pub + '/all',
    getEntryAllList: '/' + pub + '/getEntryAllList',
    getYearMonth: '/' + pub + '/getYearMonth',
    getPayrollVehicle: '/' + pub + '/getPayrollVehicle',
}

/**
 * 获取详细
 */
export function all() {
    return axios({
        url: api.all,
        method: 'get'
    })
}

/**
 * 获取详细
 */
export function getInfo(params) {
    return axios({
        url: api.info,
        method: 'get',
        params
    })
}/**
 * 获取详细
 */
export function getDetails(params) {
    return axios({
        url: api.getDetails,
        method: 'get',
        params
    })
}

/**
 * 列表
 */
export function list(params) {
    return axios({
        url: api.list,
        method: 'get',
        params
    })
}

/**
 * 员工列表
 */
export function entryAllList(params) {
    return axios({
        url: api.getEntryAllList,
        method: 'get',
        params
    })
}

/**
 * 员工列表
 */
export function yearMonth(params) {
    return axios({
        url: api.getYearMonth,
        method: 'get',
        params
    })
}

export function getPayrollVehicle(params) {
    return axios({
        url: api.getPayrollVehicle,
        method: 'get',
        params
    })
}

/**
 * 新增记录
 * @param {*} data
 */
export function add(data) {
    return axios({
        url: api.add,
        method: 'post',
        data
    })
}

/**
 * 编辑记录
 * @param {*} data
 */
export function edit(data) {
    return axios({
        url: api.edit,
        method: 'post',
        data
    })
}

export function entry(data) {
    return axios({
        url: api.entry,
        method: 'post',
        data
    })
}

/**
 * 删除记录
 * @param {*} data
 */
export function deleted(data) {
    return axios({
        url: api.delete,
        method: 'post',
        data: data
    })
}

export function changeStatus(data) {
    return axios({
        url: api.changeStatus,
        method: 'post',
        data: data
    })
}

export function saveStatus(data) {
    return axios({
        url: api.saveStatus,
        method: 'post',
        data: data
    })
}

export function depart(data) {
    return axios({
        url: api.depart,
        method: 'post',
        data: data
    })
}

