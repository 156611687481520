<template>

  <a-row :gutter="10">
    <a-col :span="4">
      <Month
          @handleChange="onMonthChange"
      />
    </a-col>
    <a-col :span="20">
      <List
          ref="list"
      />
    </a-col>
  </a-row>
</template>
<script>
import Month from './Month'
import List from './List'

export default {
  components: {
    Month,
    List
  },
  data() {
    return {}
  },
  created() {


  },
  methods: {
    onMonthChange(item) {
      this.$refs.list.setMonth(item.month_time)
    }
  },
}

</script>
<style scoped>
.body {
  display: flex;
  border: 1px solid #e8e8e8;

  .month-menus {
    width: 260px;
  //border-right: 1px solid #e8e8e8;

    .item {
      padding: 10px;
      border-bottom: 1px solid #e8e8e8;

      &:last-child {
        border-bottom: none
      }
    }
  }


  .user-list {

    border: 1px solid #e8e8e8;

    .user-list-item {
      cursor: pointer;
      width: 260px;
      padding: 5px 15px;
      border-bottom: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
    }

    .curr {
      background: #378DDB;
      color: #ffffff;
    }
  }

  .year-list {
    width: calc(100% - 203px);
    border: 1px solid #e8e8e8;
    border-left: none;


    .year-list-item {
      padding: 10px;
      float: left;
      border-radius: 10px;
      width: 260px;
      min-height: 100px;
      margin-top: 10px;
      margin-right: 10px;
      border: 1px solid #e8e8e8;

      .year-title {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        margin-bottom: 6px;
        font-weight: 500;

        .detail {
          color: #378DDB;
        }
      }

      .operate {
        font-weight: 500;
        padding-top: 10px;
        border-top: 1px solid #e8e8e8;
        display: flex;
        justify-content: space-between;
      }
    }

    .curr {
      background: #378DDB;
      color: #ffffff;

      .year-title {
        .detail {
          color: #FFFFFF;
        }
      }
    }
  }
}

</style>
